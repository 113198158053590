import React, { useState } from "react";
import { IProfileCardProps, ProfileCard } from "../cards/ProfileCard";
import "./ProfileContainer.scss";

export interface IProfileContainerProps {
  title: string;
  usergroup: string;
  profiles: {
    rerollSelected: () => void;
    selected: IProfileCardProps;
    data: IProfileCardProps[];
  }
}

export const ProfileContainer = ({ title, profiles }: IProfileContainerProps): JSX.Element => {
  const [ignoredCards, setIgnoredCards] = useState<number[]>([]);

  const toggleIgnore = (card: number): void => {
    if (ignoredCards.includes(card)) {
      const copy: number[] = [...ignoredCards];
      copy.splice(copy.indexOf(card), 1);
      setIgnoredCards(copy);
    } else {
      setIgnoredCards([...ignoredCards, card]);
    }
  };

  const getProfileCards = (onlyIgnored: boolean): (JSX.Element | null)[] => {
    return profiles.data.map((data: IProfileCardProps, index: number): JSX.Element | null => {
      if (onlyIgnored ? !ignoredCards.includes(index) : ignoredCards.includes(index)) {
        return null;
      }

      data.actions = {
        toggleIgnored: (): void => toggleIgnore(index),
      };

      return <ProfileCard key={index} {...data}/>;
    });
  };

  const getIgnoredCards = (): JSX.Element | null => {
    const cards: (JSX.Element | null)[] = getProfileCards(true).filter((card: JSX.Element | null): boolean => card !== null);
    
    if (cards.length === 0) {
      return null;
    }

    return (
      <div className="ProfileContainer__ignoredCards">
        <div>Ignored Cards:</div>
        {cards}
      </div>
    );
  };

  return (
    <div className="ProfileContainer">
      <div className="ProfileContainer__title">Daily {title} Status</div>
      <div className="ProfileContainer__selected">
        <ProfileCard {...profiles.selected} actions={{ rerollSelected: profiles.rerollSelected }} selected/>
      </div>
      <div className="ProfileContainer__cards">{getProfileCards(false)}</div>
      {getIgnoredCards()}
    </div>
  );
};
import React, { useEffect, useState } from "react";
import { formatDate, plural } from "../../includes/utils";
import "./TicketCard.scss";

export enum TicketState {
  Default = "default",
  Resolved = "resolved",
  Ignored = "ignored"
}

export interface ITicketCardMeta {
  expanded: boolean;
  state: TicketState;
}

export interface ITicketCardProps {
  jiraKey: string;
  platform: string;
  status: string;
  duedate: string;
  assignee: {
    name: string;
    picture: string;
  }
  lastUpdated: string;
  meta?: ITicketCardMeta;
  updateCardStatus: (newMeta: ITicketCardMeta) => void;
}

// Used for proper capitalization for platforms with acronyms
const platformMap: { [key: string]: string } = {
  "ab-tasty": "AB Tasty",
  "vwo": "VWO"
};

export const TicketCard = ({ jiraKey, platform, status, duedate, assignee, lastUpdated, meta, updateCardStatus }: ITicketCardProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(meta?.expanded ?? false);
  const [ticketState, setTicketState] = useState<TicketState>(meta?.state ?? TicketState.Default);

  const getPlatformName = (): string => {
    return platformMap[platform] ?? platform;
  };

  const toggleExpanded = (): void => {
    setIsExpanded(!isExpanded);
  };

  const toggleResolved = (): void => {
    if (ticketState === TicketState.Resolved) {
      setTicketState(TicketState.Default);
    } else {
      setTicketState(TicketState.Resolved);
    }
  };

  const toggleIgnored = (): void => {
    if (ticketState === TicketState.Ignored) {
      setTicketState(TicketState.Default);
    } else {
      setTicketState(TicketState.Ignored);
    }
  };

  const formatLastUpdated = (): string => {
    const now: Date = new Date(Date.now());
    const updatedAt: Date = new Date(lastUpdated);
    const millisDelta: number = now.getTime() - updatedAt.getTime();
    const hoursDelta: number = millisDelta / (1000 * 3600);
    const hoursDeltaRemainder: number = Math.floor(hoursDelta % 24);
    const daysDelta: number = Math.floor(hoursDelta / 24);

    let result = "";
    if (daysDelta > 0) {
      result += `${daysDelta} ${plural("day", daysDelta)}, `;
    }

    if (hoursDeltaRemainder % 24 !== 0) {
      result += `${hoursDeltaRemainder} ${plural("hour", hoursDeltaRemainder)}`;
    } else {
      result = result.substring(0, result.length - 2);
    }

    return result;
  };

  const getTicketCardModifiers = (): string => {
    const classes: string[] = ["TicketCard"];

    if (isExpanded) {
      classes.push("TicketCard--expanded");
    }

    if (ticketState !== TicketState.Default) {
      classes.push(`TicketCard--${ticketState.toString()}`);
    }

    return classes.join(" ");
  };

  const getActionButtons = (): JSX.Element => {
    // const buttonData: IButtonProps[] = [
    //   {
    //     text: "Resolve",
    //     active: ticketState === TicketState
    //   },
    //   {

    //   }
    // ];

    const baseClass: string = "TicketCard__buttons";
    const resolveClass: string = `${baseClass}Resolve${ticketState === TicketState.Resolved ? ` ${baseClass}Resolve--active` : ""}`;
    const ignoreClass: string = `${baseClass}Ignore${ticketState === TicketState.Ignored ? ` ${baseClass}Ignore--active` : ""}`;

    return (
      <div className={baseClass}>
        <button className={resolveClass} onClick={toggleResolved}>Resolve</button>
        <button className={ignoreClass} onClick={toggleIgnored}>Ignore</button>
      </div>
    );
  };

  // This is used to prevent clicking on links from expanding or unexpanding the card
  const removePropagation = (event: Event): void => {
    event.stopPropagation();
  };

  useEffect((): void => {
    for (const interactable of document.querySelectorAll(".TicketCard a")) {
      interactable.addEventListener("click", removePropagation);
    }
  }, [(): void => {
    for (const interactable of document.querySelectorAll(".TicketCard a")) {
      interactable.removeEventListener("click", removePropagation);
    }
  }]);

  useEffect((): void => {
    updateCardStatus({ expanded: isExpanded, state: ticketState });
  }, [isExpanded, ticketState]);

  return (
    <div className={getTicketCardModifiers()} onClick={toggleExpanded}>
      <div className="TicketCard__alwaysShown">
        <div className="TicketCard__top">
          <a className="TicketCard__jiraKey" href={`https://crometrics.atlassian.net/browse/${jiraKey}`} target="_blank" rel="noreferrer">{jiraKey}</a>
          <div className="TicketCard__platform">{getPlatformName()}</div>
        </div>
        <div className="TicketCard__bottom">
          <div className="TicketCard__status">{status}</div>
          <div className="TicketCard__duedate">Due {formatDate(duedate)}</div>
        </div>
      </div>
      <div className="TicketCard__expandedSection">
        <div className="TicketCard__assignee">
          <img className="TicketCard__assigneePicture" src={assignee.picture} alt={`${assignee.name}'s headshot`}/>
          <div className="TicketCard__assigneeName">{assignee.name}</div>
        </div>
        <div className="TicketCard__lastUpdated">Updated {formatLastUpdated()} ago</div>
        {getActionButtons()}
      </div>
    </div>
  );
};
import React from "react";
import { Button, IButtonProps } from "./generic/Button";
import "./ActionButtons.scss";

export interface IActionButtonsProps {
  buttons: IButtonProps[];
}

export const ActionButtons = ({ buttons }: IActionButtonsProps): JSX.Element => {
  const getButtons = (): JSX.Element[] => {
    return buttons.map((data: IButtonProps, index: number): JSX.Element => {
      return <Button key={index} {...data} style={data.style}/>;
    });
  };

  return (
    <div className="ActionButtons">{getButtons()}</div>
  );
};
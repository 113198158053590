import React, { useState } from "react";
import { ActionButtons } from "../ui/ActionButtons";
import { ButtonStyle, IButtonProps } from "../ui/generic/Button";
import "./ProfileCard.scss";

export interface IProfileCardProps {
  id: string;
  name: string;
  pictures: {
    small: string;
    large: string;
  },
  outOfOffice: boolean;
  lastSpoke?: number;
  selected?: boolean;
  actions: {
    toggleIgnored?: () => void;
    rerollSelected?: () => void;
  }
}

export const ProfileCard = ({ name, pictures, outOfOffice, lastSpoke, selected, actions }: IProfileCardProps): JSX.Element => {
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const getCardModifiers = (): string => {
    const baseClass: string = "ProfileCard";
    const modifiers: string[] = [baseClass];

    if (outOfOffice) {
      modifiers.push(`${baseClass}--disabled`);
    }

    if (selected) {
      modifiers.push(`${baseClass}--selected`);
    }

    return modifiers.join(" ");
  };

  const getOutOfOffice = (): JSX.Element | null => {
    return outOfOffice ? <span className="ProfileCard__outOfOffice"> (OOO)</span> : null;
  };

  const getLastSpoke = (): string => {
    if (lastSpoke) {
      return `You last spoke ${lastSpoke} days ago`;
    } else {
      return "No previous status check ins";
    }
  };

  const getActionButtons = (): JSX.Element | null => {
    if (selected) {
      const buttonData: IButtonProps[] = [
        {
          text: "Complete",
          style: ButtonStyle.Primary,
          active: isComplete,
          disabled: isComplete,
          onClick: (): void => setIsComplete(!isComplete)
        },
        {
          text: "Reroll",
          disabled: isComplete,
          onClick: (): void => {
            if (actions?.rerollSelected) {
              actions.rerollSelected();
            }
          }
        }
      ];

      return <ActionButtons buttons={buttonData}/>;
    } else {
      return <button className="ProfileCard__toggleIgnore" onClick={actions.toggleIgnored}>&times;</button>;
    }
  };

  const imageSize: string = selected ? pictures.large : pictures.small;

  return (
    <div className={getCardModifiers()}>
      <img className="ProfileCard__headshot" src={imageSize} alt={`${name}'s headshot`}/>
      <div className="ProfileCard__content">
        <div className="ProfileCard__info">
          <div className="ProfileCard__name">
            {name}
            {getOutOfOffice()}
          </div>
          <div className="ProfileCard__lastSpoke">{getLastSpoke()}</div>
        </div>
        {getActionButtons()}
      </div>
    </div>
  );
};
import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { IProfileCardProps } from "../cards/ProfileCard";
import { IProfileContainerProps, ProfileContainer } from "../containers/ProfileContainer";
import "./DailyStatus.scss";
import { random } from "../../includes/utils";

interface IRawProfileData {
  edges: {
    node: IProfileCardProps;
  }[]
}

export const DailyStatus = (): JSX.Element => {
  const rawProfileData: { [key: string]: IRawProfileData } = useStaticQuery(graphql`
    query DailyStatusQuery {
      allEngineerProfile {
        edges {
          node {
            id
            name
            pictures {
              small
              large
            }
            outOfOffice
          }
        }
      }
      allPgmProfile {
        edges {
          node {
            id
            name
            pictures {
              small
              large
            }
            outOfOffice
          }
        }
      }
    }
  `);

  const parseProfileData = (parentNode: IRawProfileData): IProfileCardProps[] => {
    return parentNode.edges.map((data: { node: IProfileCardProps }): IProfileCardProps => {
      return { ...data.node };
    });
  };

  const engineerProfiles: IProfileCardProps[] = parseProfileData(rawProfileData.allEngineerProfile);
  const pgmProfiles: IProfileCardProps[] = parseProfileData(rawProfileData.allPgmProfile);

  const getRandomProfile = (profiles: IProfileCardProps[], current?: IProfileCardProps): IProfileCardProps => {
    let selected: IProfileCardProps;
    do {
      selected = profiles[random(0, profiles.length)];
    } while (selected.outOfOffice || selected.id === current?.id);

    return selected;
  };

  const [selectedEngineer, setSelectedEngineer] = useState<IProfileCardProps>(getRandomProfile(engineerProfiles));
  const [selectedPgm, setSelectedPgm] = useState<IProfileCardProps>(getRandomProfile(pgmProfiles));

  const containers: IProfileContainerProps[] = [
    {
      title: "Engineer",
      usergroup: "engineer",
      profiles: {
        rerollSelected: (): void => setSelectedEngineer(getRandomProfile(engineerProfiles, selectedEngineer)),
        selected: selectedEngineer,
        data: engineerProfiles
      }
    },
    {
      title: "PGM",
      usergroup: "pgm",
      profiles: {
        rerollSelected: (): void => setSelectedPgm(getRandomProfile(pgmProfiles, selectedPgm)),
        selected: selectedPgm,
        data: pgmProfiles
      }
    }
  ];

  const getProfileContainers = (): JSX.Element[] => {
    return containers.map((container: IProfileContainerProps, index: number): JSX.Element => {
      return <ProfileContainer key={index} {...container}/>;
    });
  };

  return (
    <div className="DailyStatus">{getProfileContainers()}</div>
  );
};
import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ITicketCardProps } from "../cards/TicketCard";
import { defaultFilterType, FilterType, ITicketContainerProps, TicketContainer } from "../containers/TicketContainer";
import { DailyStatus } from "./DailyStatus";
import "../../global/scss/global.scss";
import "./EggMonitoring.scss";

interface ITicketData {
  allPastDueJiraTickets: {
    edges: {
      node: ITicketCardProps
    }[]
  }
  allDueTodayJiraTickets: {
    edges: {
      node: ITicketCardProps
    }[]
  }
  allDueTomorrowJiraTickets: {
    edges: {
      node: ITicketCardProps
    }[]
  }
}

export const EggMonitoring = (): JSX.Element => {
  const [currentFilter, setCurrentFilter] = useState<FilterType>(defaultFilterType);

  const rawTicketData: ITicketData = useStaticQuery(graphql`
    query EggMonitoringQuery {
      allPastDueJiraTickets {
        edges {
          node {
            duedate
            jiraKey
            status
            platform
            assignee {
              picture
              name
            }
            lastUpdated
          }
        }
      }
      allDueTodayJiraTickets {
        edges {
          node {
            duedate
            jiraKey
            platform
            status
            assignee {
              picture
              name
            }
            lastUpdated
          }
        }
      }
      allDueTomorrowJiraTickets {
        edges {
          node {
            duedate
            jiraKey
            platform
            status
            assignee {
              picture
              name
            }
            lastUpdated
          }
        }
      }
    }
  `);

  const getNodeArray = (edges: { node: ITicketCardProps }[]): ITicketCardProps[] => {
    const nodes: ITicketCardProps[] = [];

    for (const edge of edges) {
      if (edge.node.jiraKey === "test") {
        continue;
      }

      for (const key in edge.node) {
        if (edge.node[key] === "placeholder") {
          edge.node[key] = "";
        }
      }

      nodes.push(edge.node);
    }

    return nodes;
  };

  const getTicketContainers = (): JSX.Element | (JSX.Element | null)[] => {
    const containerMap: ITicketContainerProps[] = [
      {
        title: "Past Due",
        ticketData: getNodeArray(rawTicketData.allPastDueJiraTickets.edges),
        filters: {
          currentFilter,
          updateFilter: setCurrentFilter
        }
      },
      {
        title: "Due Today",
        ticketData: getNodeArray(rawTicketData.allDueTodayJiraTickets.edges),
        filters: {
          currentFilter,
          updateFilter: setCurrentFilter
        }
      },
      {
        title: "Due Tomorrow",
        ticketData: getNodeArray(rawTicketData.allDueTomorrowJiraTickets.edges),
        filters: {
          currentFilter,
          updateFilter: setCurrentFilter
        }
      },
    ];

    let hasIncludeFilterModule: boolean = false;
    return containerMap.map((props: ITicketContainerProps): JSX.Element | null => {
      if (props.ticketData.length === 0) {
        return null;
      } else {
        if (!hasIncludeFilterModule) {
          hasIncludeFilterModule = true;
          props.filters.includeFilterModule = true;
        }

        return <TicketContainer key={props.title} {...props}/>;
      }
    });
  };

  return (
    <div className="EggMonitoring">
      {getTicketContainers()}
      <DailyStatus/>
    </div>
  );
};
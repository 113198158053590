import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { EggMonitoring } from "../components/tabs/EggMonitoring";

interface IIndexProps {
  data: IIndexQueryData;
}

const Index = ({ data }: IIndexProps): JSX.Element => {
  return <>
    <Helmet>
      <title>{data.site.siteMetadata.name}</title>
      <meta name="description" content={data.site.siteMetadata.description}/>
    </Helmet>
    <EggMonitoring/>
  </>;
};
export default Index;

interface IIndexQueryData {
  site: {
    siteMetadata: {
      name: string;
      description: string;
      auth: string;
    }
  }
}

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
import React, { ChangeEvent, useEffect, useState } from "react";
import { dateToNumber, plural } from "../../includes/utils";
import { ITicketCardMeta, ITicketCardProps, TicketCard, TicketState } from "../cards/TicketCard";
import "./TicketContainer.scss";

export interface ITicketContainerProps {
  title: string;
  ticketData: ITicketCardProps[];
  filters: {
    currentFilter: FilterType;
    updateFilter: (type: FilterType) => void;
    includeFilterModule?: boolean;
  }
}

export enum FilterType {
  DueDate = 0,
  Engineer = 1,
  LastUpdated = 2,
  Alphabetical = 3
}

const filterNames: { [key in FilterType]: string } = {
  [FilterType.DueDate]: "Due Date",
  [FilterType.Engineer]: "Engineer",
  [FilterType.LastUpdated]: "Last Updated",
  [FilterType.Alphabetical]: "Alphabetical"
};

export const defaultFilterType: FilterType = 0;
const localStorageKey: string = "last-ticket-filter-type";

type TFilterTypeFunction = {
  [key in FilterType]: (tickets: JSX.Element[]) => JSX.Element[];
};

const sortTickets = (tickets: JSX.Element[], compareFunction: (a: JSX.Element, z: JSX.Element) => number): JSX.Element[] => {
  return tickets.sort((a: JSX.Element, z: JSX.Element): number => {
    return compareFunction(a, z);
  }).sort((a: JSX.Element, z: JSX.Element): number => {
    if (a.props.meta?.state === TicketState.Ignored) {
      if (z.props.meta?.state === TicketState.Ignored) {
        return 0;
      } else {
        return 1;
      }
    } else if (a.props.meta?.state === TicketState.Resolved) {
      if (z.props.meta?.state === TicketState.Ignored) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return -1;
    }
  });
};

const filterFunctions: TFilterTypeFunction = {
  [FilterType.DueDate]: (tickets: JSX.Element[]): JSX.Element[] => {
    return sortTickets(tickets, (a: JSX.Element, z: JSX.Element): number => dateToNumber(a.props.duedate) - dateToNumber(z.props.duedate));
  },
  [FilterType.Engineer]: (tickets: JSX.Element[]): JSX.Element[] => {
    return sortTickets(tickets, (a: JSX.Element, z: JSX.Element): number => a.props.assignee.name.localeCompare(z.props.assignee.name));
  },
  [FilterType.LastUpdated]: (tickets: JSX.Element[]): JSX.Element[] => {
    return sortTickets(tickets, (a: JSX.Element, z: JSX.Element): number => dateToNumber(a.props.lastUpdated) - dateToNumber(z.props.lastUpdated));
  },
  [FilterType.Alphabetical]: (tickets: JSX.Element[]): JSX.Element[] => {
    return sortTickets(tickets, (a: JSX.Element, z: JSX.Element): number => a.props.jiraKey.split("-")[0].localeCompare(z.props.jiraKey.split("-")[0]));
  }
};

export const TicketContainer = ({ title, ticketData, filters }: ITicketContainerProps): JSX.Element | null => {
  if (ticketData.length === 0) {
    return null;
  }

  const [data, setData] = useState<ITicketCardProps[]>(ticketData);

  const updateFilter = (event: ChangeEvent<HTMLSelectElement>): void => {
    filters.updateFilter(parseInt(event.target.value));

    window.localStorage.setItem(localStorageKey, event.target.value);
  };

  const createTicketsFromData = (ticketData: ITicketCardProps[]): JSX.Element[] => {
    const updateCardStatus = (index: number, newMeta: ITicketCardMeta): void => {
      const dataCopy: ITicketCardProps[] = [...data];
      dataCopy[index].meta = newMeta;
      setData(dataCopy);
    };

    return ticketData.map((item: ITicketCardProps, index: number): JSX.Element => {
      return <TicketCard key={index} {...item} updateCardStatus={(newMeta: ITicketCardMeta): void => updateCardStatus(index, newMeta)}/>;
    });
  };

  const getNumberOfActionsRequired = (): number => {
    return ticketData.filter((item: ITicketCardProps): boolean => {
      return !item.meta || item.meta.state === TicketState.Default;
    }).length;
  };

  const sortTickets = (tickets: JSX.Element[]): JSX.Element[] => {
    return filterFunctions[filters.currentFilter](tickets);
  };

  const getFilterElement = (): JSX.Element | null => {
    if (!filters.includeFilterModule) {
      return null;
    }

    const getOptions = (): JSX.Element[] => {
      const options: JSX.Element[] = [];

      for (const filterType in filterNames) {
        const name: string = filterNames[filterType];
        options.push(<option key={name} value={filterType}>{name}</option>);
      }

      return options;
    };

    return <>
      <span>Filter by:</span>
      <select className="TicketContainer__filter" onChange={updateFilter} value={filters.currentFilter}>{getOptions()}</select>
    </>;
  };

  useEffect((): void => {
    const storageFilter: string | null = window.localStorage.getItem(localStorageKey);
    filters.updateFilter(storageFilter ? parseInt(storageFilter) : defaultFilterType);
  }, []);

  const actionsRequired: number = getNumberOfActionsRequired();

  return (
    <div className="TicketContainer">
      <div className="TicketContainer__header">
        <div className="TicketContainer__title">
          <span>Tickets {title}</span>
          <span className="TicketContainer__subtitle"> ({actionsRequired} {plural("action", actionsRequired)} required)</span>
        </div>
        <div className="TicketContainer__options">
          <button className="TicketContainer__expandControl">Expand All</button>
          {getFilterElement()}
        </div>
      </div>
      <div className="TicketContainer__tickets">{sortTickets(createTicketsFromData(ticketData))}</div>
    </div>
  );
};
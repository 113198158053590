import { Link } from "gatsby";
import React from "react";
import "./Button.scss";

export enum ButtonStyle {
  Primary = "primary",
  Muted = "muted"
}

export interface IButtonProps {
  text: string;
  style?: ButtonStyle;
  onClick?: () => void;
  href?: string;
  active?: boolean;
  disabled?: boolean;
}

export const Button = ({ text, style = ButtonStyle.Muted, onClick, href, active, disabled }: IButtonProps): JSX.Element => {
  const getClassNames = (): string => {
    const baseClass: string = "Button";
    const classes: string[] = [baseClass, `${baseClass}--${style}`];

    if (active) {
      classes.push(`${baseClass}--active`);
    }

    if (disabled) {
      classes.push(`${baseClass}--disabled`);
    }

    return classes.join(" ");
  };

  const doNothing = () => { return; };
  
  // Handle links to the same domain
  if (href?.startsWith("/")) {
    return <Link className={getClassNames()} to={disabled ? "" : href} onClick={disabled ? doNothing : onClick}>{text}</Link>;
  }
  
  // Handle links to other domains
  if (href) {
    return <a className={getClassNames()} href={disabled ? "" : href} onClick={disabled ? doNothing : onClick} rel="noreferrer">{text}</a>;
  }

  // Handle any case where the button is not a link
  return <button className={getClassNames()} onClick={onClick} disabled={disabled}>{text}</button>;
};